$darkBlue: #0D3151;
$lightBlue: #1D9ED9;

@font-face {
  font-family: Roboto;
  src: url('./../fonts/Roboto-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: Roboto;
  src: url('./../fonts/Roboto-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Roboto;
  src: url('./../fonts/Roboto-Bold.ttf');
  font-weight: 700;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  background: white;
}

.c-error-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.c-error-image {
  height: 580px;
  width: 520px;
  background: url('./../img/error.png');
  background-repeat: no-repeat;
  background-position: center;
}

.c-error-rentlio {
  width: 146px;
  height: 33px;
  background: url('./../img/rentlio.svg');
}

.c-error-code {
  margin-top: 50px;
  font-weight: bold;
  font-size: 150px;
  line-height: 176px;
  color: $darkBlue;
}

.c-error-text {
  max-width: 280px;
  margin-bottom: 40px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $darkBlue;
}

.c-error-name{
  max-width: 280px;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: $darkBlue;
}

.c-error-button {
  display: inline-block;
  cursor: pointer;
  background: $lightBlue;
  padding: 20px 30px;
  color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.2);
}
